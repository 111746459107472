import React from "react";
import { Routes, Route } from "react-router-dom";

import Cod from "../pages/Cod";
import Expense from "../pages/Expense";

const routes = [
  {
    path: "/cod",
    exact: true,
    element: <Cod />,
    private: false,
  },
  {
    path: "/expense",
    exact: true,
    element: <Expense />,
    private: false,
  },
];

export default function Navigation() {
  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
}
