import axios from "axios";
import { toast } from "sonner";

export const analyzeImage = async (formData, base64Image) => {
  try {
    console.log("called api","https://api.deliveryplus.in/ocr/analyze");
    console.log(formData,"this is the formData");
    const response = await axios.post(`https://api.deliveryplus.in/ocr/analyze`, {
      ...formData,
      image: base64Image,
    });
    return response.data;
  } catch (error) {
    console.log(error.response,"error");
    toast.error(error.response  || "An error occurred",{
      duration: 5000,
      
    });
    console.error("Error analyzing image:", error);
    throw error;
  }
};